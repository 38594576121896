const DexScreenerChart = () => {
  const link =
    "https://dexscreener.com/base/0x8361e0fd714da989874ccbf34175d64673b1b3d4?embed=1&theme=dark&trades=0&info=0";

  return (
    <iframe
      id="dextools-widget"
      title="DEXTools Trading Chart"
      width="100%"
      height="600"
      src={link}
    ></iframe>
  );
};

export default DexScreenerChart;
